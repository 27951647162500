import React from 'react';
import './header.scss';
import log from '../../../assets/images/header/Grupo 12@2x.png'
import i18next from "i18next";
import {Link} from "react-router-dom";
import lang from "../../../../src/assets/images/header/ico99.svg";


interface Props {
    translator?: any
}

class HeaderComponent extends React.Component<Props> {

    state = {
        currentLanguage: false,
        show: false
    }

    changeLanguage() {
        this.setState({currentLanguage: !this.state.currentLanguage})
        if (this.state.currentLanguage) {
            i18next.changeLanguage("es")
        } else {
            i18next.changeLanguage("en")
        }
    }

    render() {

        return (

            <header>

                <label className="iconM float-nav" htmlFor="menu-bar"
                       onClick={() => this.setState({show: !this.state.show})}/>
                <img className="icon float-icon" src={log} alt=""/>
                <input type="checkbox" id="menu-bar"></input>
                <nav className={this.state.show ? "menu show" : "menu"}>
                    <a href="#QueEs"
                       onClick={() => this.setState({show: false})}><span>{this.props.translator('LinkTextOne')}</span></a>
                    <a href="#TuApp"
                       onClick={() => this.setState({show: false})}><span>{this.props.translator('LinkTextTwo')}</span></a>
                    <a href="#Planes"
                       onClick={() => this.setState({show: false})}><span>{this.props.translator('LinkTextThree')}</span></a>
                    <a href="#Contacto"
                       onClick={() => this.setState({show: false})}><span>{this.props.translator('LinkTextFour')}</span></a>
                    <Link to="/faqs"> FAQ</Link>

                    <div className="header-buttons d-flex align-items-center">
                        <img className="lang-icon" src={lang} alt="" onClick={() => this.changeLanguage()}/>
                        |
                        <a className="login-button"
                           href="https://admin.mypopstoreapp.com/login"><span>{this.props.translator('LinkTextEight')}</span></a>
                        <a className="register-button" href="/form">{this.props.translator('LinkTextNine')}</a>
                    </div>

                </nav>
            </header>


        );
    }
}

export default HeaderComponent;
